import { useMemo } from 'react';

import slackInstall from '../../../assets/img/lp-slack-install.png';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { err2s } from '../../../utils/common';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';

export function SlackConnectFailedModal(props: {
  error: string;
  onCancel: () => void;
  onRetry: () => Promise<void>;
}): JSX.Element {
  const { error } = props;
  const ui = useMemo(() => {
    if (error === 'access_denied') {
      return {
        header: 'Oops! Please Allow Luna Park on Slack!',
        body: 'Please allow access or contact your Workspace’s administrator if you are unable to do so',
      };
    }
    return { header: 'Oops! Something is wrong!', body: err2s(error) };
  }, [error]);

  const {
    call: handleRetry,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(props.onRetry);

  return (
    <ModalWrapper containerClassName='w-172' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-16 py-10'>
        <header className='font-medium text-2xl flex flex-col items-center'>
          <img
            src={slackInstall}
            alt='Add Luna Park to Slack'
            className='mb-4'
          />
          <div>{ui.header}</div>
        </header>
        <section className='w-full items-center text-center mt-5'>
          <div className='mb-4'>{ui.body}</div>
          <div>
            If you need further assistance, please reach out to us{' '}
            <a
              target='_blank'
              href='https://lunapark.com/contact/'
              rel='noreferrer'
              className='font-bold text-primary'
            >
              here
            </a>
            .
          </div>
        </section>
        <footer className='mt-10 w-full flex items-center justify-center gap-5'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-40 h-10 flex items-center justify-center'
          >
            Cancel
          </button>

          <button
            type='button'
            onClick={handleRetry}
            className='btn-primary w-40 h-10 flex items-center justify-center'
            disabled={isRunning}
          >
            {isRunning ? 'Retrying' : 'Retry'}
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function SlackConnectSuccessfulModal(props: {
  primaryButtonText: string;
  onComplete: () => void;
}): JSX.Element {
  return (
    <ModalWrapper containerClassName='w-120' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-8 py-10'>
        <header className='font-medium text-2xl flex items-center justify-center'>
          🎉 Slack App Installed Successfully!
        </header>
        <section className='w-full items-center text-center my-10'>
          Great work. You’ve taken the first step towards more team engagement.
          We look forward to helping you onboard your whole team soon.
        </section>
        <footer className='w-full flex flex-col items-center justify-center'>
          <button
            type='button'
            onClick={() => props.onComplete()}
            className='btn-primary px-4 min-w-40 h-10 flex items-center justify-center'
          >
            {props.primaryButtonText}
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}
