import { useLocation, useNavigate } from '@remix-run/react';
import { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { SlackUtils } from '../../Slack';
import {
  SlackConnectFailedModal,
  SlackConnectSuccessfulModal,
} from './SlackConnectModal';

function useSlackInstalledState() {
  const location = useLocation();
  const navigate = useNavigate();

  const slackInstalledState = useMemo(
    () =>
      SlackUtils.IsSlackInstalledState(location.state) ? location.state : null,
    [location.state]
  );

  const cleanState = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: location.search,
    });
  }, [location.pathname, location.search, navigate]);

  return useMemo(
    () => ({
      slackInstalledState,
      cleanState,
    }),
    [slackInstalledState, cleanState]
  );
}

export function useSlackInstalledCallbackModal(props?: {
  onCancel?: () => void;
}) {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const { slackInstalledState, cleanState } = useSlackInstalledState();

  useEffectOnce(() => {
    if (!slackInstalledState) return;

    if (slackInstalledState.success) {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <SlackConnectSuccessfulModal
            primaryButtonText={'Done'}
            onComplete={() => {
              cleanState();
              p.internalOnConfirm();
            }}
          />
        ),
      });
    } else {
      const error = slackInstalledState.error;
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <SlackConnectFailedModal
            onRetry={async () => {
              window.location.href = await SlackUtils.GenerateSlackInstallURL({
                scenario: slackInstalledState.scenario,
              });
            }}
            onCancel={() => {
              cleanState();
              p.internalOnCancel();
              if (props?.onCancel) props.onCancel();
            }}
            error={error}
          />
        ),
      });
    }
  });

  return useMemo(
    () => ({
      slackInstalledState,
    }),
    [slackInstalledState]
  );
}
